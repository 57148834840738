import { useEffect, useState } from "react";
import { GamesList } from "./GamesList";
import { GameDetail, RefereePreview } from "./types";
import { RefereeDetails } from "./RefDetails";
import "./App.css";
import { TeamDetails } from "./TeamDetails";
import { fetchGames } from "./apis";

function App() {
  const [displayRef, setDisplayRef] = useState<string | null>(null);
  const [displayTeam, setDisplayTeam] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [games, setGames] = useState<GameDetail[] | null>([]);
  const [refs, setRefs] = useState<RefereePreview[] | null>([]);
  const [tab, setTab] = useState<"games" | "referees">("games");

  useEffect(() => {
    setLoading(true);
    fetchGames().then((g) => {
      setGames(g.games);
      setLoading(false);
    });


    fetch("/get/refs")
      .then((s) => s.json())
      .then((res) => {
        setRefs(res);
      });
  }, []);

  return (
    <>
      <header
        style={{ background: "#1d428a", color: "#fff", padding: "10px 20px" }}
      >
        <h1 style={{ margin: 0, fontSize: "24px" }}>Whistle Analytics</h1>
      </header>
      <nav
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "12px 0",
          background: "#ececec",
        }}
      >
        <div
          onClick={() => setTab("games")}
          style={{
            cursor: "pointer",
            padding: "10px 20px",
            fontWeight: tab === "games" ? "bold" : "normal",
          }}
        >
          Games
        </div>
        <div
          onClick={() => setTab("referees")}
          style={{
            cursor: "pointer",
            padding: "10px 20px",
            fontWeight: tab === "referees" ? "bold" : "normal",
          }}
        >
          Referees
        </div>
      </nav>
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8273005765327672"
        crossOrigin="anonymous"
      ></script>

      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-8273005765327672"
        data-ad-slot="3767692600"
        data-ad-format="auto"
        // data-adtest="on"
        data-full-width-responsive="true"
      ></ins>
      <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
      <main
        style={{
          display: "flex",
          height: "calc(100vh - 80px)",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <div style={{ flex: 1, overflow: "auto", padding: "24px" }}>
          {tab === "referees" && (
            <section>
              <h2>Referees</h2>
              {loading && <div className="spinner"></div>}
              <div style={{ marginBottom: "16px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "8px 0",
                    fontWeight: "bold",
                    borderBottom: "2px solid #ddd",
                  }}
                >
                  <span>Name</span>
                  <span>Games Officiated</span>
                </div>
              </div>
              {refs
                ?.sort(
                  (a, b) =>
                    b.games.length - a.games.length ||
                    a.name.localeCompare(b.name)
                )
                .map((r) => (
                  <div
                    key={r.id}
                    onClick={() => {
                      setDisplayRef(r.id);
                      setDisplayTeam(null);
                    }}
                    style={{
                      cursor: "pointer",
                      padding: "8px 0",
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #ddd",
                    }}
                  >
                    <span>{r.name}</span>
                    <span>{r.games.length}</span>
                  </div>
                ))}
            </section>
          )}

          {tab === "games" && (
            <section>
              <h2>Games</h2>
              {loading && <div className="spinner"></div>}
              <GamesList
                list={games || []}
                onTeamClick={(t) => {
                  setDisplayRef(null);
                  setDisplayTeam(t);
                }}
                onRefClick={(r) => {
                  setDisplayRef(r);
                  setDisplayTeam(null);
                }}
              />
            </section>
          )}
        </div>
        <div
          className={`referee-details-container ${
            displayRef || displayTeam ? "open" : ""
          }`}
        >
          {displayRef && (
            <RefereeDetails
              referee={displayRef}
              onSetReferee={(r) => {
                setDisplayRef(r);
                setDisplayTeam(null);
              }}
            />
          )}
          {displayTeam && (
            <TeamDetails
              team={displayTeam}
              onSetTeam={(t) => {
                setDisplayTeam(t);
                setDisplayRef(null);
              }}
            />
          )}
        </div>
      </main>
    </>
  );
}

export default App;
