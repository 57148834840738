import { useEffect, useMemo, useState } from "react";
import { GameLogEntry, RefereeGameDetails } from "./types";

export const TeamDetails = ({
  team,
  onSetTeam,
}: {
  team: string;
  onSetTeam: (ref: string | null) => void;
}) => {
  const [details, setDetails] = useState<RefereeGameDetails | null>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    fetch(`/get/team/${team}`)
      .then((s) => s.json())
      .then((res) => {
        setDetails(res);
        setLoading(false);
      });
  }, [team]);

  const callsByGame = useMemo(() => {
    const grouped: Record<string, GameLogEntry[]> = {};
    details?.calls.forEach((entry) => {
      if (!grouped[entry.GAME_ID]) {
        grouped[entry.GAME_ID] = [];
      }
      grouped[entry.GAME_ID].push(entry);
    });
    return grouped;
  }, [details]);

  const callsForPlayer = useMemo(() => {
    const grouped: Record<
      string,
      { calls: GameLogEntry[]; games: Set<string> }
    > = {};
    details?.calls.forEach((entry) => {
      if (
        entry.PLAYER2_TEAM_ABBREVIATION === team &&
        entry.PLAYER2_ID !== "0"
      ) {
        if (!grouped[entry.PLAYER2_ID]) {
          grouped[entry.PLAYER2_ID] = {
            calls: [],
            games: new Set(),
          };
        }
        grouped[entry.PLAYER2_ID].calls.push(entry);
        grouped[entry.PLAYER2_ID].games.add(entry.GAME_ID);
      }
    });
    return grouped;
  }, [details]);

  const callsAgainsPlayer = useMemo(() => {
    const grouped: Record<
      string,
      { calls: GameLogEntry[]; games: Set<string> }
    > = {};

    details?.calls.forEach((entry) => {
      if (
        entry.PLAYER1_TEAM_ABBREVIATION === team &&
        entry.PLAYER1_ID !== "0"
      ) {
        if (!grouped[entry.PLAYER1_ID]) {
          grouped[entry.PLAYER1_ID] = {
            calls: [],
            games: new Set(),
          };
        }
        grouped[entry.PLAYER1_ID].calls.push(entry);
        grouped[entry.PLAYER1_ID].games.add(entry.GAME_ID);
      }
    });
    return grouped;
  }, [details]);

  const callsForRef = useMemo(() => {
    const grouped: Record<
      string,
      { calls: GameLogEntry[]; games: Set<string> }
    > = {};
    details?.calls.forEach((entry) => {
      if (entry.PLAYER2_TEAM_ABBREVIATION === team) {
        const homeDescriptionReferee = extractReferee(entry.HOMEDESCRIPTION);
        const neutralDescriptionReferee = extractReferee(
          entry.NEUTRALDESCRIPTION
        );
        const visitorDescriptionReferee = extractReferee(
          entry.VISITORDESCRIPTION
        );

        const ref =
          homeDescriptionReferee ||
          neutralDescriptionReferee ||
          visitorDescriptionReferee;

        if (ref) {
          if (!grouped[ref]) {
            grouped[ref] = {
              calls: [],
              games: new Set(),
            };
          }
          grouped[ref].calls.push(entry);
          grouped[ref].games.add(entry.GAME_ID);
        }
      }
    });
    return grouped;
  }, [details]);

  const callsAgainsRef = useMemo(() => {
    const grouped: Record<
      string,
      { calls: GameLogEntry[]; games: Set<string> }
    > = {};

    details?.calls.forEach((entry) => {
      if (entry.PLAYER1_TEAM_ABBREVIATION === team) {
        const homeDescriptionReferee = extractReferee(entry.HOMEDESCRIPTION);
        const neutralDescriptionReferee = extractReferee(
          entry.NEUTRALDESCRIPTION
        );
        const visitorDescriptionReferee = extractReferee(
          entry.VISITORDESCRIPTION
        );

        const ref =
          homeDescriptionReferee ||
          neutralDescriptionReferee ||
          visitorDescriptionReferee;

        if (ref) {
          if (!grouped[ref]) {
            grouped[ref] = {
              calls: [],
              games: new Set(),
            };
          }
          grouped[ref].calls.push(entry);
          grouped[ref].games.add(entry.GAME_ID);
        }
      }
    });
    return grouped;
  }, [details]);

  const callsForTeam = useMemo(() => {
    const grouped: Record<
      string,
      { calls: GameLogEntry[]; games: Set<string> }
    > = {};

    details?.calls.forEach((entry) => {
      if (entry.PLAYER2_TEAM_ABBREVIATION) {
        if (!grouped[entry.PLAYER2_TEAM_ABBREVIATION]) {
          grouped[entry.PLAYER2_TEAM_ABBREVIATION] = {
            calls: [],
            games: new Set(),
          };
        }
        grouped[entry.PLAYER2_TEAM_ABBREVIATION].calls.push(entry);
        grouped[entry.PLAYER2_TEAM_ABBREVIATION].games.add(entry.GAME_ID);
      }
    });
    return grouped;
  }, [details]);

  const callsAgainsTeam = useMemo(() => {
    const grouped: Record<
      string,
      { calls: GameLogEntry[]; games: Set<string> }
    > = {};

    details?.calls.forEach((entry) => {
      if (entry.PLAYER1_TEAM_ABBREVIATION) {
        if (!grouped[entry.PLAYER1_TEAM_ABBREVIATION]) {
          grouped[entry.PLAYER1_TEAM_ABBREVIATION] = {
            calls: [],
            games: new Set(),
          };
        }
        grouped[entry.PLAYER1_TEAM_ABBREVIATION].calls.push(entry);
        grouped[entry.PLAYER1_TEAM_ABBREVIATION].games.add(entry.GAME_ID);
      }
    });
    return grouped;
  }, [details]);

  const refRecord = useMemo(() => {
    const refRecord: Record<string, { win: number; loss: number }> = {};

    details?.games.forEach((entry) => {
      const winner =
        entry.AWAY_SCORE > entry.HOME_SCORE
          ? entry.AWAY_TEAM_ABBREVIATION
          : entry.HOME_TEAM_ABBREVIATION;
      const loser =
        entry.AWAY_SCORE < entry.HOME_SCORE
          ? entry.AWAY_TEAM_ABBREVIATION
          : entry.HOME_TEAM_ABBREVIATION;

      entry.REFEREES.forEach((r) => {
        if (!refRecord[r]) {
          refRecord[r] = { win: 0, loss: 0 };
        }

        if (winner === team) {
          refRecord[r].win++;
        }

        if (loser === team) {
          refRecord[r].loss++;
        }
      });
    });
    return refRecord;
  }, [details]);

  const homeAwayRecord = useMemo(() => {
    let home: number = 0;
    let away: number = 0;

    details?.games.forEach((entry) => {
      entry.AWAY_SCORE > entry.HOME_SCORE ? away++ : home++;
    });

    return { home, away };
  }, [details]);

  const totalGames = details?.games.length || 1;

  return (
    <div style={{ fontSize: "14px", padding: 24 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "16px",
        }}
      >
        <h2 style={{ margin: 0 }}>{team}</h2>

        <span
          style={{ cursor: "pointer", color: "#d0021b" }}
          onClick={() => onSetTeam(null)}
        >
          Close
        </span>
      </div>

      {loading && <div className="spinner"></div>}
      {!loading && (
        <>
          <div style={{ marginBottom: "20px" }}>
            <h4>Total Games: {totalGames}</h4>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <h4>Top 10 Calls For Players</h4>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ width: "48%" }}>
                <h5>Calls For Per Game</h5>
                {Object.keys(callsForPlayer)
                  .sort(
                    (a, b) =>
                      callsForPlayer[b].calls.length /
                        callsForPlayer[b].games.size -
                      callsForPlayer[a].calls.length /
                        callsForPlayer[a].games.size
                  )
                  .slice(0, 10)
                  .map((id, index) => (
                    <div key={id}>
                      {index + 1}. {callsForPlayer[id].calls[0].PLAYER2_NAME} -{" "}
                      {(
                        callsForPlayer[id].calls.length /
                        callsForPlayer[id].games.size
                      ).toFixed(2)}{" "}
                      ({callsForPlayer[id].games.size} games)
                    </div>
                  ))}
              </div>
              <div style={{ width: "48%" }}>
                <h5>Total Calls For</h5>
                {Object.keys(callsForPlayer)
                  .sort(
                    (a, b) =>
                      callsForPlayer[b].calls.length -
                      callsForPlayer[a].calls.length
                  )
                  .slice(0, 10)
                  .map((id, index) => (
                    <div key={id}>
                      {index + 1}. {callsForPlayer[id].calls[0].PLAYER2_NAME} -{" "}
                      {callsForPlayer[id].calls.length} (
                      {callsForPlayer[id].games.size} games)
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div style={{ marginBottom: "20px" }}>
            <h4>Top 10 Calls Against Players</h4>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ width: "48%" }}>
                <h5>Calls Against Per Game</h5>
                {Object.keys(callsAgainsPlayer)
                  .sort(
                    (a, b) =>
                      callsAgainsPlayer[b].calls.length /
                        callsAgainsPlayer[b].games.size -
                      callsAgainsPlayer[a].calls.length /
                        callsAgainsPlayer[a].games.size
                  )
                  .slice(0, 10)
                  .map((id, index) => (
                    <div key={id}>
                      {index + 1}. {callsAgainsPlayer[id].calls[0].PLAYER1_NAME}{" "}
                      -{" "}
                      {(
                        callsAgainsPlayer[id].calls.length /
                        callsAgainsPlayer[id].games.size
                      ).toFixed(2)}
                      ({callsAgainsPlayer[id].games.size} games)
                    </div>
                  ))}
              </div>
              <div style={{ width: "48%" }}>
                <h5>Total Calls Against</h5>
                {Object.keys(callsAgainsPlayer)
                  .sort(
                    (a, b) =>
                      callsAgainsPlayer[b].calls.length -
                      callsAgainsPlayer[a].calls.length
                  )
                  .slice(0, 10)
                  .map((id, index) => (
                    <div key={id}>
                      {index + 1}. {callsAgainsPlayer[id].calls[0].PLAYER1_NAME}{" "}
                      - {callsAgainsPlayer[id].calls.length} (
                      {callsAgainsPlayer[id].games.size} games)
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <h4>Top 10 Refs Calls For</h4>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ width: "48%" }}>
                <h5>Calls For Per Game</h5>
                {Object.keys(callsForRef)
                  .sort(
                    (a, b) =>
                      callsForRef[b].calls.length / callsForRef[b].games.size -
                      callsForRef[a].calls.length / callsForRef[a].games.size
                  )
                  .slice(0, 10)
                  .map((id, index) => (
                    <div key={id}>
                      {index + 1}. {id} -{" "}
                      {(
                        callsForRef[id].calls.length /
                        callsForRef[id].games.size
                      ).toFixed(2)}{" "}
                      ({callsForRef[id].games.size} games)
                    </div>
                  ))}
              </div>
              <div style={{ width: "48%" }}>
                <h5>Total Calls For</h5>
                {Object.keys(callsForRef)
                  .sort(
                    (a, b) =>
                      callsForRef[b].calls.length - callsForRef[a].calls.length
                  )
                  .slice(0, 10)
                  .map((id, index) => (
                    <div key={id}>
                      {index + 1}. {id} - {callsForRef[id].calls.length} (
                      {callsForRef[id].games.size} games)
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div style={{ marginBottom: "20px" }}>
            <h4>Top 10 Refs Calls Against</h4>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ width: "48%" }}>
                <h5>Calls Against Per Game</h5>
                {Object.keys(callsAgainsRef)
                  .sort(
                    (a, b) =>
                      callsAgainsRef[b].calls.length /
                        callsAgainsRef[b].games.size -
                      callsAgainsRef[a].calls.length /
                        callsAgainsRef[a].games.size
                  )
                  .slice(0, 10)
                  .map((id, index) => (
                    <div key={id}>
                      {index + 1}. {id} -{" "}
                      {(
                        callsAgainsRef[id].calls.length /
                        callsAgainsRef[id].games.size
                      ).toFixed(2)}
                      ({callsAgainsRef[id].games.size} games)
                    </div>
                  ))}
              </div>
              <div style={{ width: "48%" }}>
                <h5>Total Calls Against</h5>
                {Object.keys(callsAgainsRef)
                  .sort(
                    (a, b) =>
                      callsAgainsRef[b].calls.length -
                      callsAgainsRef[a].calls.length
                  )
                  .slice(0, 10)
                  .map((id, index) => (
                    <div key={id}>
                      {index + 1}. {id} - {callsAgainsRef[id].calls.length} (
                      {callsAgainsRef[id].games.size} games)
                    </div>
                  ))}
              </div>
            </div>
          </div>

          {/* <div style={{ marginBottom: "20px" }}>
            <h4>Top 10 Calls For Teams</h4>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ width: "48%" }}>
                <h5>Calls For Per Game</h5>
                {Object.keys(callsForTeam)
                  .sort(
                    (a, b) =>
                      callsForTeam[b].calls.length /
                        callsForTeam[b].games.size -
                      callsForTeam[a].calls.length / callsForTeam[a].games.size
                  )
                  .slice(0, 10)
                  .map((id, index) => (
                    <div key={id}>
                      {index + 1}.{" "}
                      {callsForTeam[id].calls[0].PLAYER2_TEAM_NICKNAME} -{" "}
                      {(
                        callsForTeam[id].calls.length /
                        callsForTeam[id].games.size
                      ).toFixed(2)}{" "}
                      ({callsForTeam[id].games.size} games)
                    </div>
                  ))}
              </div>
              <div style={{ width: "48%" }}>
                <h5>Total Calls For</h5>
                {Object.keys(callsForTeam)
                  .sort(
                    (a, b) =>
                      callsForTeam[b].calls.length -
                      callsForTeam[a].calls.length
                  )
                  .slice(0, 10)
                  .map((id, index) => (
                    <div key={id}>
                      {index + 1}.{" "}
                      {callsForTeam[id].calls[0].PLAYER2_TEAM_NICKNAME} -{" "}
                      {callsForTeam[id].calls.length} (
                      {callsForTeam[id].games.size} games)
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div style={{ marginBottom: "20px" }}>
            <h4>Top 10 Calls Against Teams</h4>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ width: "48%" }}>
                <h5>Calls Against Per Game</h5>
                {Object.keys(callsAgainsTeam)
                  .sort(
                    (a, b) =>
                      callsAgainsTeam[b].calls.length /
                        callsAgainsTeam[b].games.size -
                      callsAgainsTeam[a].calls.length /
                        callsAgainsTeam[a].games.size
                  )
                  .slice(0, 10)
                  .map((id, index) => (
                    <div key={id}>
                      {index + 1}.{" "}
                      {callsAgainsTeam[id].calls[0].PLAYER1_TEAM_NICKNAME} -{" "}
                      {(
                        callsAgainsTeam[id].calls.length /
                        callsAgainsTeam[id].games.size
                      ).toFixed(2)}{" "}
                      ({callsAgainsTeam[id].games.size} games)
                    </div>
                  ))}
              </div>
              <div style={{ width: "48%" }}>
                <h5>Total Calls Against</h5>
                {Object.keys(callsAgainsTeam)
                  .sort(
                    (a, b) =>
                      callsAgainsTeam[b].calls.length -
                      callsAgainsTeam[a].calls.length
                  )
                  .slice(0, 10)
                  .map((id, index) => (
                    <div key={id}>
                      {index + 1}.{" "}
                      {callsAgainsTeam[id].calls[0].PLAYER1_TEAM_NICKNAME} -{" "}
                      {callsAgainsTeam[id].calls.length} (
                      {callsAgainsTeam[id].games.size} games)
                    </div>
                  ))}
              </div>
            </div>
          </div> */}
          <div style={{ marginBottom: "20px" }}>
            <h4>Referee Records</h4>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ width: "48%" }}>
                <h5>Top 15</h5>
                {Object.keys(refRecord)
                  .sort(
                    (a, b) =>
                      refRecord[b].win /
                        (refRecord[b].loss + refRecord[b].win) -
                        refRecord[a].win /
                          (refRecord[a].loss + refRecord[a].win) ||
                      refRecord[b].loss +
                        refRecord[b].win -
                        (refRecord[a].loss + refRecord[a].win)
                  )
                  .slice(0, 15)
                  .map((id, index) => {
                    const w = refRecord[id].win || 0;
                    const l = refRecord[id].loss || 0;

                    return (
                      <div key={id}>
                        {index + 1}. {id}: {w} - {l}
                      </div>
                    );
                  })}
              </div>
              <div style={{ width: "48%" }}>
                <h5>Bottom 15</h5>
                {Object.keys(refRecord)
                  .sort(
                    (a, b) =>
                      refRecord[b].win /
                        (refRecord[b].loss + refRecord[b].win) -
                        refRecord[a].win /
                          (refRecord[a].loss + refRecord[a].win) ||
                      refRecord[a].loss +
                        refRecord[a].win -
                        (refRecord[b].loss + refRecord[b].win)
                  )
                  .reverse()
                  .slice(0, 15)
                  .map((id, index) => {
                    const w = refRecord[id].win || 0;
                    const l = refRecord[id].loss || 0;

                    return (
                      <div key={id}>
                        {index + 1}. {id}: {w} - {l}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>

          {details?.games.map((game) => (
            <div
              key={game.GAME_ID}
              style={{
                marginBottom: "20px",
                borderBottom: "1px solid #ddd",
                paddingBottom: "10px",
              }}
            >
              <div>
                {game.AWAY_SCORE > game.HOME_SCORE ? (
                  <strong style={{ color: "#d0021b" }}>
                    {game.AWAY_TEAM_ABBREVIATION} ({game.AWAY_SCORE})
                  </strong>
                ) : (
                  <>
                    {game.AWAY_TEAM_ABBREVIATION} ({game.AWAY_SCORE})
                  </>
                )}{" "}
                @{" "}
                {game.AWAY_SCORE < game.HOME_SCORE ? (
                  <strong style={{ color: "#d0021b" }}>
                    {game.HOME_TEAM_ABBREVIATION} ({game.HOME_SCORE})
                  </strong>
                ) : (
                  <>
                    {game.HOME_TEAM_ABBREVIATION} ({game.HOME_SCORE})
                  </>
                )}
              </div>

              <div>
                <strong>Number of Calls:</strong>{" "}
                {callsByGame[game.GAME_ID]?.length || 0}
              </div>

              <ul>
                {callsByGame[game.GAME_ID]?.map((entry) => (
                  <li key={entry.GAME_ID + entry.EVENTNUM}>
                    {entry.HOMEDESCRIPTION ||
                      entry.VISITORDESCRIPTION ||
                      entry.NEUTRALDESCRIPTION}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

const extractReferee = (description: string) => {
  if (!description) return null;
  const match = description.match(/\((\w\.[A-Za-z\s]+)\)$/);
  return match ? match[1] : null;
};
