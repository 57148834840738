import { firestore } from "firebase-admin";
import { GameDetail } from "./types";

import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  query,
  getDocs,
  limit,
  startAfter,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBOMZ5STqedYs9OWC5BHhb3D2GIFGT89mE",
  authDomain: "whistle-analytics-30a88.firebaseapp.com",
  projectId: "whistle-analytics-30a88",
  storageBucket: "whistle-analytics-30a88.appspot.com",
  messagingSenderId: "1034167580524",
  appId: "1:1034167580524:web:31a805244a92e7b504e549",
  measurementId: "G-WG72JD8K8R",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export interface FetchGamesResult {
  games: GameDetail[];
  lastVisible: firestore.QueryDocumentSnapshot<firestore.DocumentData> | null;
}

export const fetchGames = async (
  lastDoc: any | null = null,
  pageSize: number = 20
) => {
  const gamesCollection = collection(db, "2324_games");
  let q = query(gamesCollection, limit(pageSize));

  if (lastDoc) {
    q = query(gamesCollection, startAfter(lastDoc), limit(pageSize));
  }

  const snapshot = await getDocs(q);
  const games = snapshot.docs.map((doc) => doc.data() as GameDetail);

  return {
    games,
    lastVisible: snapshot.docs[snapshot.docs.length - 1],
  };
};
