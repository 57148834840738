import { useEffect } from "react";
import "./App.css";
import { teamInfo } from "./constants";
import { GameDetail } from "./types";

export const GamesList = ({
  list,
  onRefClick,
  onTeamClick,
}: {
  list: GameDetail[];
  onRefClick: (ref: string) => void;
  onTeamClick: (team: string) => void;
}) => {

  return (
    <div>
      {list?.map((l) => (
        <div
          key={l.GAME_ID}
          style={{
            marginBottom: "20px",
            padding: "10px 0",
            borderBottom: "1px solid #ddd",
          }}
        >
          <div
            style={{ display: "flex", alignItems: "center", fontSize: "18px" }}
          >
            {/* <img
              src={teamInfo[l.AWAY_TEAM_ABBREVIATION].logo}
              alt={l.AWAY_TEAM_ABBREVIATION}
              style={{ width: "30px", marginRight: "10px" }}
            /> */}
            <span
              style={{
                color:
                  l.AWAY_SCORE > l.HOME_SCORE
                    ? teamInfo[l.AWAY_TEAM_ABBREVIATION].primaryColor
                    : "#aaa",
                fontWeight: l.AWAY_SCORE > l.HOME_SCORE ? "bolder" : "normal",
                cursor: "pointer",
              }}
              onClick={() => {
                onTeamClick(l.AWAY_TEAM_ABBREVIATION);
              }}
            >
              {l.AWAY_TEAM_ABBREVIATION} ({l.AWAY_SCORE})
            </span>
            <span style={{ margin: "0 10px" }}> @ </span>
            {/* <img
              src={teamInfo[l.HOME_TEAM_ABBREVIATION].logo}
              alt={l.HOME_TEAM_ABBREVIATION}
              style={{ width: "30px", marginRight: "10px" }}
            /> */}
            <span
              style={{
                color:
                  l.HOME_SCORE > l.AWAY_SCORE
                    ? teamInfo[l.HOME_TEAM_ABBREVIATION].primaryColor
                    : "#aaa",
                fontWeight: l.AWAY_SCORE < l.HOME_SCORE ? "bolder" : "normal",
                cursor: "pointer",
              }}
              onClick={() => {
                onTeamClick(l.HOME_TEAM_ABBREVIATION);
              }}
            >
              {l.HOME_TEAM_ABBREVIATION} ({l.HOME_SCORE})
            </span>
          </div>
          <div
            style={{
              display: "flex",
              columnGap: 8,
              alignItems: "center",
              fontSize: "14px",
            }}
          >
            <strong>Referees:</strong>
            {l.REFEREES?.map((r) => (
              <span
                onClick={() => onRefClick(r)}
                key={r}
                style={{ cursor: "pointer", color: "#1d428a" }}
              >
                {r}
              </span>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
