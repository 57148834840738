export const teamInfo: Record<
  string,
  { logo: string; primaryColor: string; secondaryColor: string }
> = {
  ATL: {
    logo: "/logos/atl.png",
    primaryColor: "#E03A3E",
    secondaryColor: "#C1D32F",
  },
  BOS: {
    logo: "/logos/bos.png",
    primaryColor: "#007A33",
    secondaryColor: "#BA9653",
  },
  BKN: {
    logo: "/logos/bkn.png",
    primaryColor: "#000000",
    secondaryColor: "#FFFFFF",
  },
  CHA: {
    logo: "/logos/cha.png",
    primaryColor: "#1D1160",
    secondaryColor: "#00788C",
  },
  CHI: {
    logo: "/logos/chi.png",
    primaryColor: "#CE1141",
    secondaryColor: "#000000",
  },
  CLE: {
    logo: "/logos/cle.png",
    primaryColor: "#860038",
    secondaryColor: "#041E42",
  },
  DAL: {
    logo: "/logos/dal.png",
    primaryColor: "#00538C",
    secondaryColor: "#B8C4CA",
  },
  DEN: {
    logo: "/logos/den.png",
    primaryColor: "#0E2240",
    secondaryColor: "#FEC524",
  },
  DET: {
    logo: "/logos/det.png",
    primaryColor: "#C8102E",
    secondaryColor: "#006BB6",
  },
  GSW: {
    logo: "/logos/gsw.png",
    primaryColor: "#1D428A",
    secondaryColor: "#FDB927",
  },
  HOU: {
    logo: "/logos/hou.png",
    primaryColor: "#CE1141",
    secondaryColor: "#C4CED4",
  },
  IND: {
    logo: "/logos/ind.png",
    primaryColor: "#002D62",
    secondaryColor: "#FDBB30",
  },
  LAC: {
    logo: "/logos/lac.png",
    primaryColor: "#C8102E",
    secondaryColor: "#1D428A",
  },
  LAL: {
    logo: "/logos/lal.png",
    primaryColor: "#552583",
    secondaryColor: "#FDB927",
  },
  MEM: {
    logo: "/logos/mem.png",
    primaryColor: "#5D76A9",
    secondaryColor: "#12173F",
  },
  MIA: {
    logo: "/logos/mia.png",
    primaryColor: "#98002E",
    secondaryColor: "#F9A01B",
  },
  MIL: {
    logo: "/logos/mil.png",
    primaryColor: "#00471B",
    secondaryColor: "#EEE1C6",
  },
  MIN: {
    logo: "/logos/min.png",
    primaryColor: "#0C2340",
    secondaryColor: "#236192",
  },
  NOP: {
    logo: "/logos/nop.png",
    primaryColor: "#0C2340",
    secondaryColor: "#C8102E",
  },
  NYK: {
    logo: "/logos/nyk.png",
    primaryColor: "#006BB6",
    secondaryColor: "#F58426",
  },
  OKC: {
    logo: "/logos/okc.png",
    primaryColor: "#007AC1",
    secondaryColor: "#EF3B24",
  },
  ORL: {
    logo: "/logos/orl.png",
    primaryColor: "#0077C0",
    secondaryColor: "#C4CED4",
  },
  PHI: {
    logo: "/logos/phi.png",
    primaryColor: "#006BB6",
    secondaryColor: "#ED174C",
  },
  PHX: {
    logo: "/logos/phx.png",
    primaryColor: "#1D1160",
    secondaryColor: "#E56020",
  },
  POR: {
    logo: "/logos/por.png",
    primaryColor: "#E03A3E",
    secondaryColor: "#000000",
  },
  SAC: {
    logo: "/logos/sac.png",
    primaryColor: "#5A2D81",
    secondaryColor: "#63727A",
  },
  SAS: {
    logo: "/logos/sas.png",
    primaryColor: "#C4CED4",
    secondaryColor: "#000000",
  },
  TOR: {
    logo: "/logos/tor.png",
    primaryColor: "#CE1141",
    secondaryColor: "#000000",
  },
  UTA: {
    logo: "/logos/uta.png",
    primaryColor: "#002B5C",
    secondaryColor: "#00471B",
  },
  WAS: {
    logo: "/logos/was.png",
    primaryColor: "#002B5C",
    secondaryColor: "#E31837",
  },
};
